// Migrated
<template lang="pug">
ScreenWidthProvider(v-slot="{ isWider }")
  SlideCarousel.startpage-campaign-slide.w-100.mb-5(
    :slides="campaignsSlide"
    :constant-display-navigation="isWider('md')"
    title-class="h1 font-weight-medium"
    :autoplay="{ delay: 10000 }"
    dots-type="custom"
    prevent-swiper-update
    only-render-visible-slides
  )
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  props: {
    campaigns: {
      type: Array,
      default: [],
    },
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),

    campaignsSlide () {
      return [ ...(this.campaigns || []) ]
        .filter(Boolean)
        .filter(campaign => campaign?.sitemap === 2)
        .map(campaign => ({
          image: campaign?.hero_image_cloudinary,
          type: 'sized',
          title: campaign?.topic,
          description: campaign?.description?.replace(/\r/g, ' '),
          link: {
            text: 'readMore',
            href: `${this.localeURLs.campaign}/${campaign.page}`,
          },
        }))
    },
  },
})
</script>
